import style from './cargaArchivo.module.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const CargaArchivo = () => {
	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();
	function onChange(event) {
		let selectedFile;
		let uploadData = new FormData();
		selectedFile = event.target.files[0];
		uploadData.append('file', selectedFile, selectedFile.name);
		uploadFile(uploadData);
	}

	const uploadFile = async (uploadData) => {
		try {
			const url = `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/upload`;
			const response = await fetch(url, {
				method: 'POST',
				body: uploadData,
			});
			const jsonData = await response.json();
			const status = response.status;
			console.log(jsonData);

			if (status === 200) {
				MySwal.fire({
					position: 'center',
					icon: 'success',
					title: 'Tu registro ha sido realizado correctamente',
					showConfirmButton: true,
				}).then((res) => {
					if (res.isConfirmed) {
						navigate('/admin/');
					}
				});
			}
		} catch (err) {
			MySwal.fire({
				icon: 'error',
				title: 'Conexion Error',
				text: 'Error de conexión, vuelve a intentarlo',
			});
		}
	};

	return (
		<div className={style.principalBoxCA}>
			<h2>Subir Archivo</h2>
			<form>
				<div>
					<input type='file' name='file' onChange={onChange} />
				</div>
				<Button
					onClick={uploadFile}
					style={{ marginTop: '10px' }}
					variant='contained'>
					Enviar
				</Button>
			</form>
		</div>
	);
};

export default CargaArchivo;
