import { Button } from '@mui/material';
import React from 'react';
import style from './dataAdmins.module.css';

const DataAdmins = ({ user }) => {
	const logOut = () => {
		localStorage.removeItem('user');
		window.location.reload();
	};

	return (
		<div className={style.boxAdmins}>
			<h1 className={style.titleAdmin}>
				Bienvenido {user?.name.toUpperCase()}, al modulo de
				Administradores
			</h1>
			<Button type='submit' onClick={logOut}>
				Cerrar Sesión
			</Button>
		</div>
	);
};

export default DataAdmins;
