import React, { useState, useEffect } from 'react';
import style from './login.module.css';
import engranes from '../../assets/images/KKWEGH.jpg';
import logo from '../../assets/images/LOGO_FINAL_CYSEM.png';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';

const Login = () => {
	const [user, setUser] = useState('{}');

	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();
	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		onSubmit: (formData) => {
			getLogin(formData.email, formData.password);
		},
	});

	const loginUser = () => {
		localStorage.setItem('user', JSON.stringify(user));
	};

	const reload = () => {
		window.location.reload(true);
	};

	const parseJwt = (token) => {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map(function (c) {
					return (
						'%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
					);
				})
				.join(''),
		);

		return JSON.parse(jsonPayload);
	};

	const getLogin = async (email, password) => {
		try {
			const data = {
				email,
				password,
			};
			const url = `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/auth/login`;
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			const jsonData = await response.json();

			const status = response.status;

			if (status === 401) {
				MySwal.fire({
					icon: 'error',
					title: 'Conexion Error',
					text: jsonData,
				});
				return;
			}
			if (status === 402) {
				MySwal.fire({
					title: 'Registrate',
					text: jsonData,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Registrarme',
				}).then((res) => {
					if (res.isConfirmed) {
						navigate('/registro');
					}
				});
				return;
			}

			const preUser = parseJwt(jsonData.token);

			setUser(preUser);

			MySwal.fire({
				position: 'center',
				icon: 'success',
				title: 'haz iniciado sesión correctamente',
				showConfirmButton: true,
			}).then((res) => {
				if (res.isConfirmed) {
					if (preUser?.role === 'administrador') {
						navigate('/admin');
						reload();
					} else if (preUser?.role === 'cliente') {
						navigate('/productos');
						reload();
					}
				}
			});
		} catch (err) {
			MySwal.fire({
				icon: 'error',
				title: 'Conexion Error',
				text: 'Error de conexión, vuelve a intentarlo',
			});
		}
	};

	useEffect(() => {
		loginUser();
	}, [user]);

	return (
		<div className={style.boxLogin}>
			<div className={style.boxForm}>
				<div className={style.formBox}>
					<img className={style.logo} alt='logo' src={logo} />
					<h2>Inicia Sesión</h2>
					<p>
						¿No tienes cuenta?{' '}
						<Link to='/registro'>Registrate</Link>
					</p>
					<form className={style.form} onSubmit={formik.handleSubmit}>
						<TextField
							id='outlined-basic'
							variant='outlined'
							size='small'
							name='email'
							label='Email'
							required
							margin='normal'
							onChange={formik.handleChange}
						/>
						<TextField
							type='password'
							id='outlined-basic'
							variant='outlined'
							size='small'
							name='password'
							label='Password'
							required
							margin='normal'
							onChange={formik.handleChange}
						/>
						<div className={style.divCheck}>
							<FormControlLabel
								control={
									<Checkbox
										onChange={formik.handleChange}
										name='check'
									/>
								}
								label='Recuerdame'
							/>
							<Link to='/forgotpassword'>
								Olvide mi contraseña
							</Link>
						</div>
						<Button
							type='submit'
							style={{
								marginTop: '10px',
								backgroundColor: '#0A2B6B',
								color: '#C6F40A',
								fontWeigth: 'bolder',
								textTransform: 'none',
							}}
							variant='contained'>
							Iniciar Sesión
						</Button>
					</form>
				</div>
			</div>
			<div className={style.boxImage}>
				<img
					className={style.imgEngranes}
					alt='engranes'
					src={engranes}
				/>
			</div>
		</div>
	);
};

const initialValues = () => {
	return {
		email: '',
		password: '',
	};
};

const validationSchema = () => {
	return {
		email: Yup.string().email().required(true),
		password: Yup.string().required(true),
	};
};

export default Login;
