import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import style from './carrito.module.css';
import { useNavigate } from 'react-router-dom';
import TablaProductos from '../../components/TablaProductos/TablaProductos';
import WhatsappButton from '../../components/whatsapp/WhatsappButton';

const Carrito = () => {
	const [user, setUser] = useState();

	useEffect(() => {
		const items = JSON.parse(localStorage?.getItem('user'));

		if (items) {
			setUser(items);
		}
	}, []);

	return (
		<>
			<div className={style.welcomeUser}>
				<span style={{ textTransform: 'capitalize' }}>
					<b>BIENVENIDO:</b> {user?.name}
				</span>

				<span>{user?.email}</span>
			</div>

			<TablaProductos user={user} />
		</>
	);
};

export default Carrito;
