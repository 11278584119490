import React, { useEffect, useState } from 'react';
import Nav from './components/nav/Nav';
import Home from './screens/home/Home';
import SandwichBar from './components/SandwichBar/SandwichBar';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './screens/login/Login';
import Registro from './screens/registro/Registro';
import Contacto from './screens/contacto/Contacto';
import Administrador from './screens/Administrador/Administrador';
import Nosotros from './screens/Nosotros/Nosotros';
import Carrito from './screens/Carrito/Carrito';
import Footer from './components/footer/Footer';

const ProtectedRoutes = ({ children, role }) => {
	if (role !== 'administrador') {
		return <Navigate to='/productos' />;
	}
	return children;
};

function App() {
	const [open, setOpen] = useState(false);

	const [user, setUser] = useState();

	const screenWidth = window.innerWidth;

	useEffect(() => {
		const items = JSON.parse(localStorage?.getItem('user'));
		if (!user === undefined) {
			localStorage.setItem('user', JSON.stringify({}));
		}

		if (items) {
			setUser(items);
		}
	}, []);

	return (
		<div>
			{screenWidth < 688 ? (
				<SandwichBar open={open} user={user} />
			) : (
				<Nav user={user} />
			)}
			<Routes>
				<Route
					path='/'
					element={
						<Home
							screenWidth={screenWidth}
							setOpen={setOpen}
							open={open}
							user={user}
						/>
					}
				/>
				<Route
					path='/productos'
					element={
						<Home
							screenWidth={screenWidth}
							setOpen={setOpen}
							open={open}
							user={user}
						/>
					}
				/>
				<Route path='/login' element={<Login />} />
				<Route path='/registro' element={<Registro />} />
				<Route path='/contacto' element={<Contacto />} />
				<Route path='/nosotros' element={<Nosotros />} />
				<Route path='/carrito' element={<Carrito />} />

				<Route
					path='/admin/*'
					element={
						<ProtectedRoutes role={user?.role}>
							<Administrador user={user} />
						</ProtectedRoutes>
					}
				/>
			</Routes>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default App;
