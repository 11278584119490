import { useState, useEffect } from 'react';
import style from './nav.module.css';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/LOGO-FINAL-BLANCO.png';

const Nav = ({ user }) => {
	return (
		<>
			<div className={style.principalBox}>
				<div className={style.menuBox}>
					<img className={style.logo} src={logo} />
					<ul>
						<Link className={style.links} to='/productos'>
							Productos
						</Link>
						<Link className={style.links} to='/nosotros'>
							Nosotros
						</Link>
						<Link className={style.links} to='/contacto'>
							Contacto
						</Link>
						{user?.name === undefined ? (
							<>
								<Link className={style.links} to='/login'>
									Inicio Sesión
								</Link>
								<Link className={style.links} to='/registro'>
									Registro
								</Link>
							</>
						) : null}
						{user?.role === 'cliente' && (
							<Link className={style.links} to='/carrito'>
								Carrito
							</Link>
						)}
						{user?.role === 'administrador' && (
							<Link className={style.links} to='/admin'>
								Administrador
							</Link>
						)}
					</ul>
				</div>
			</div>
		</>
	);
};

export default Nav;
