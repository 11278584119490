import style from './formRegistro.module.css';
import logo from '../../assets/images/LogoCysem.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';

const FormRegistro = () => {
	const navigate = useNavigate();
	const MySwal = withReactContent(Swal);
	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		onSubmit: (formData) => {
			if (formData.password !== formData.confirmPassword) {
				MySwal.fire({
					icon: 'error',
					title: 'Error en las contraseñas',
					text: 'Las contraseñas deben ser iguales',
				});
				return;
			}
			const { nombre, email, password, celular, empresa } = formData;
			registered(nombre, email, password, celular, empresa);
		},
	});

	const registered = async (name, email, password, phone, company, role) => {
		try {
			const data = {
				name,
				email,
				password,
				phone: '5555555555',
				company: 'cysem',
				role: 'administrador',
			};
			const url = `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/auth/register`;
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			const jsonData = await response.json();
			const status = response.status;

			if (status !== 200) {
				MySwal.fire({
					icon: 'error',
					title: 'Conexion Error',
					text: jsonData,
				});
				return;
			}

			MySwal.fire({
				position: 'center',
				icon: 'success',
				title: 'Tu registro ha sido realizado correctamente',
				showConfirmButton: true,
			}).then((res) => {
				if (res.isConfirmed) {
					navigate('/admin/');
				}
			});
		} catch (err) {
			MySwal.fire({
				icon: 'error',
				title: 'Conexion Error',
				text: 'Error de conexión, vuelve a intentarlo',
			});
		}
	};

	return (
		<div className={style.boxLogin}>
			<div className={style.boxForm}>
				<div className={style.formBox}>
					<img alt='logo' src={logo} />
					<h2>Registra un administrador</h2>

					<form className={style.form} onSubmit={formik.handleSubmit}>
						<TextField
							id='outlined-basic'
							variant='outlined'
							size='small'
							name='nombre'
							label='Nombre'
							required
							margin='dense'
							onChange={formik.handleChange}
						/>
						<TextField
							id='outlined-basic'
							variant='outlined'
							size='small'
							name='email'
							label='Email'
							required
							margin='dense'
							onChange={formik.handleChange}
						/>
						<TextField
							type='password'
							id='outlined-basic'
							variant='outlined'
							size='small'
							name='password'
							label='Password'
							required
							margin='dense'
							onChange={formik.handleChange}
						/>
						<TextField
							type='password'
							id='outlined-basic'
							variant='outlined'
							size='small'
							name='confirmPassword'
							label='Confirma tu password'
							required
							margin='dense'
							onChange={formik.handleChange}
						/>

						<Button
							type='submit'
							style={{
								marginTop: '10px',
								backgroundColor: '#0A2B6B',
								color: '#C6F40A',
								fontWeigth: 'bolder',
								textTransform: 'none',
							}}
							variant='contained'>
							Registrarse
						</Button>
					</form>
				</div>
			</div>
		</div>
	);
};

const initialValues = () => {
	return {
		nombre: '',
		email: '',
		password: '',
		confirmPassword: '',
	};
};

const validationSchema = () => {
	return {
		email: Yup.string().email().required(true),
		password: Yup.string().required(true),
		confirmPassword: Yup.string().required(),
	};
};

export default FormRegistro;
