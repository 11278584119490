import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	token: '',
	name: '',
	password: '',
	phone: '',
	company: '',
	role: '',
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		auth: (state) => {
			return state.value;
		},
	},
});

export const { auth } = authSlice.actions;

export default authSlice.reducer;
