import style from './tableProductos.module.css';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { number } from 'yup/lib/locale';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

const columns = [
	{
		field: 'codigoProducto',
		headerName: 'Código de producto',
		width: 300,
		editable: false,
	},
	{
		field: 'description',
		headerName: 'Descripción',
		width: 500,
		editable: false,
	},
	{
		field: 'disponibilidad',
		headerName: 'Disponibilidad',
		width: 130,
		editable: false,
	},
	{
		field: 'marca',
		headerName: 'Marca',
		width: 300,
		editable: false,
	},
	{
		field: 'precio',
		headerName: 'Precio',
		width: 130,
		editable: false,
	},
	{
		field: 'cantidad',
		headerName: 'Cantidad',
		width: 130,
		type: number,
		editable: false,
	},
	{
		field: 'subtotal',
		headerName: 'Sub Total',
		width: 130,
		type: number,
		editable: false,
	},
	{
		field: 'iva',
		headerName: 'IVA',
		width: 130,
		type: number,
		editable: false,
	},
	{
		field: 'total',
		headerName: 'Total',
		width: 130,
		editable: false,
	},
	{
		field: 'delete',
		headerName: 'Eliminar	',
		width: 130,
		editable: false,
	},
];

const TablaProductos = (user) => {
	const [selected, setSelected] = useState();

	const [carrito, setCarrito] = useState([]);

	const changeSelected = (data) => {
		setSelected(data);
	};
	const [rows, setRows] = useState([
		{
			id: '',
			codigoProducto: '',
			description: '',
			disponibilidad: 0,
			marca: '',
			precio: 0,
			cantidad: 0,
			subtotal: 0,
			iva: 0,
			total: 0,
			delete: 'Eliminar',
		},
	]);

	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();

	const onChangePage = (e) => {
		e.preventDefault();
		navigate('/productos');
	};

	const deleteProducts = async () => {
		MySwal.fire({
			title: 'Carrito',
			text: '¿Estas seguro de Eliminar el articulo?',
			icon: 'question',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Confirmar',
			confirmButtonColor: '#3085D6',
			cancelButtonColor: '#d33',
		}).then(async (res) => {
			if (res.isConfirmed) {
				try {
					const item_id = {
						_id: selected[0],
					};

					const url = `http://${process.env.REACT_APP_API_HOST}:${
						process.env.REACT_APP_API_PORT
					}/carrito/${
						JSON.parse(localStorage?.getItem('user'))?.id
					}/${item_id._id}`;
					const response = await fetch(url, {
						method: 'DELETE',
					});
					const jsonData = await response.json();

					setCarrito(jsonData);
				} catch (err) {
					console.log(err);
				}
			}
		});
	};

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetch(
				`http://${process.env.REACT_APP_API_HOST}:${
					process.env.REACT_APP_API_PORT
				}/carrito/${JSON.parse(localStorage?.getItem('user'))?.id}`,
			);
			const json = await data.json();
			setCarrito(json);
		};
		fetchData().catch(console.error);
	}, []);

	useEffect(() => {
		setRows(
			carrito?.products?.map((c) => {
				return {
					id: c?._id,
					codigoProducto: c.codigoProducto,
					description: c?.description,
					disponibilidad: c?.disponibilidad,
					marca: c?.marca,
					precio: c?.precio,
					cantidad: 1,
					subtotal: c.precio * 1,
					iva: c.precio * 0.16,
					total: c.precio * 1.16,
					delete: 'Eliminar',
				};
			}),
		);
	}, [carrito]);

	const sendMail = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				'service_ede0sqh',
				'template_gaczpcn',
				e.target,
				'MuWhbwaQj2FFjtayT',
			)
			.then((res) => {
				alert('El mail ya fue enviado');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className={style.boxTable}>
			{carrito?.products?.length === 0 ? (
				<div className={style.emtyShop}>
					<h4 className={style.h4}>
						No hay elementos en el carrtito, agrega productos para
						poderlos mostrar
					</h4>
					<Button
						onClick={onChangePage}
						type='submit'
						style={{
							backgroundColor: '#0A2B6B',
							color: '#C6F40A',
							fontWeigth: 'bolder',
							textTransform: 'none',
							marginTop: '20px',
						}}
						variant='contained'>
						Añadir articulos
					</Button>
				</div>
			) : (
				<>
					<div>
						<h4>Presupuesto: </h4>
					</div>
					<Box sx={{ height: 400, width: '100%' }}>
						{rows?.length > 0 && (
							<>
								<DataGrid
									rows={rows}
									columns={columns}
									pageSize={10}
									rowsPerPageOptions={[10]}
									SelectionOnClick
									experimentalFeatures={{
										newEditingApi: true,
									}}
									onSelectionModelChange={changeSelected}
								/>
							</>
						)}
					</Box>
					<form style={{ width: '90%' }} onSubmit={sendMail}>
						<div style={{ display: 'none' }}>
							<TextField
								type='text'
								name='name'
								id='name'
								value={user?.user?.name}
							/>
							<TextField
								type='text'
								name='email'
								id='email'
								value={user?.user?.email}
							/>
							{carrito?.products?.map((ip) => {
								return (
									<>
										<TextField
											type='text'
											name='codigoProducto'
											id='productos'
											value={ip.codigoProducto}
										/>
										<TextField
											type='text'
											name='description'
											id='productos'
											value={ip.description}
										/>
										<TextField
											type='text'
											name='marca'
											id='productos'
											value={ip.marca}
										/>
										<TextField
											type='text'
											name='precio'
											id='productos'
											value={ip.precio}
										/>
									</>
								);
							})}
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginRight: '20px',
								marginTop: '50px',
							}}>
							{selected?.length > 0 && (
								<Button
									onClick={deleteProducts}
									style={{
										backgroundColor: 'red',
										color: '#FFF',
										fontWeigth: 'bolder',
										textTransform: 'none',
										marginRight: '20px',
									}}>
									Eliminar
								</Button>
							)}
							<Button
								style={{
									backgroundColor: '#0A2B6B',
									color: '#C6F40A',
									fontWeigth: 'bolder',
									textTransform: 'none',
								}}
								type='submit'>
								Enviar Carrito
							</Button>
						</div>
					</form>
				</>
			)}
		</div>
	);
};

export default TablaProductos;
