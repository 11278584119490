import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CargaArchivo from '../../components/archivo/CargaArchivo';
import FormRegistro from '../../components/FormRegistro/FormRegistro';
import Sidebar from '../../components/Sidebar/Sidebar';
import style from './administrador.module.css';
import { useSelector, useDispatch } from 'react-redux';
import DataAdmins from '../../components/admins/DataAdmins';

const Administrador = ({ user }) => {
	return (
		<div>
			<Sidebar />
			<Routes>
				<Route path='/' element={<DataAdmins user={user} />} />
				<Route path='/cargadearchivo' element={<CargaArchivo />} />
				<Route
					path='/registraradministrador'
					element={<FormRegistro />}
				/>
			</Routes>
		</div>
	);
};

export default Administrador;
