import { WhatsApp } from '@mui/icons-material';
import React from 'react';

const WhatsappButton = () => {
	const screenWidth = window.innerWidth;

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'flex-end',
				justifyContent: 'end',
				width: '100%',
				pointer: 'hand',
			}}>
			<div>
				<a target='_blank' href='https://api.whatsapp.com/send?phone=+525611696875&text=Hola%Me%20gustaría%20realizar%20una%20cotización!'>
					{screenWidth > 600 ? (
						<WhatsApp
							sx={{
								color: 'green',
								fontSize: 80,
							}}
						/>
					) : (
						<WhatsApp
							sx={{
								color: 'green',
								fontSize: 40,
							}}
						/>
					)}
				</a>
			</div>
		</div>
	);
};

export default WhatsappButton;
