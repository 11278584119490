import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import style from './home.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cards from '../../components/cards/Cards';
import axios from 'axios';
import logo from '../../assets/images/LOGO_FINAL_CYSEM.png';
import WhatsappButton from '../../components/whatsapp/WhatsappButton';
import { CircularProgress, Pagination, Stack } from '@mui/material';

function Home({ user }) {
	const [data, setData] = useState({
		docs: [],
		hasNextPage: '',
		hasPrevPage: '',
		limit: '',
		nextPage: '',
		page: 0,
		pagingCounter: 0,
		prevePage: '',
		totalDocs: '',
		totalPages: 0,
	});

	const [page, setPage] = useState(0);
	const [prodInicial, setProdInicial] = useState('')

	const handleChange = (event, value) => {
		setPage(value);
	};

	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		onSubmit: (formData) => {
			const { search } = formData;
			const searchUpperCase = search.toUpperCase();
			setProdInicial(searchUpperCase)
			findProducts(searchUpperCase);
			// if (!search) {
			// 	getProducts();
			// }
		},
	});

	const findProducts = async (producto) => {
		try {
			const data = {
				productoBuscar: producto,
			};
			const url = `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/products/buscar/${page}/30`;
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			const jsonData = await response.json();
			setData({
				totalDocs: jsonData.cantProductos,
				docs: jsonData.productos,
				totalPages: jsonData.paginas,
				limit: jsonData.limit
			})

		} catch (err) {
			console.log(err);
		}
	};

	

	// const getProducts = async () => {
	// 	const config = {
	// 		url: `/products/${page}/30`,
	// 		method: 'get',
	// 		baseURL: `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/`,
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			'X-Requested-With': 'XMLHttpRequest',
	// 		},
	// 	};
	// 	axios
	// 		.request(config)
	// 		.then((res) => {
	// 			setData(res?.data);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		})
	// 		.finally(() => {});
	// };

	const logOut = () => {
		localStorage.removeItem('user');
		window.location.reload(true);
	};

	useEffect(() => {
		findProducts(prodInicial);
	}, [page]);
	return (
		<>
			{user?.name === undefined ? (
				<></>
			) : (
				<div className={style.welcomeUser}>
					<span style={{ textTransform: 'capitalize' }}>
						<b>BIENVENIDO:</b> {user?.name}
					</span>

					<span>{user?.email}</span>

					<Button type='submit' onClick={logOut}>
						Cerrar Sesión
					</Button>
				</div>
			)}
			<div className={style.boxLogoSearch}>
				<img className={style.logoSearch} src={logo} alt='logo' />
			</div>
			<form onSubmit={formik.handleSubmit}>
				<div className={style.boxSearch}>
					<div>
						<TextField
							style={{
								width: '306px',
								marginBottom: '15px',
							}}
							id='outlined-basic'
							variant='outlined'
							size='small'
							name='search'
							onChange={formik.handleChange}
						/>
					</div>

					<Button
						type='submit'
						style={{
							backgroundColor: '#0A2B6B',
							color: '#C6F40A',
							fontWeigth: 'bolder',
							textTransform: 'none',
						}}
						variant='contained'>
						Buscar
					</Button>
				</div>
			</form>
				{prodInicial !== '' && <p style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Fueron encontrados {data?.totalDocs} productos que coinciden con tu busqueda</p>}
			<div className={style.boxCards}>
				
				<div className={style.boxInterno}>
					{data?.docs?.length > 0 ? (
						data?.docs?.map((d) => {
							return (
								<Cards
									key={d._id}
									codigo={d.codigoProducto}
									desc={d.description}
									disponibilidad={d.disponibilidad}
									marca={d.marca}
									precio={d.precio}
									user={user}
								/>
							);
						})
					) : (
						<>
							<CircularProgress size={90} />
						</>
					)}
				</div>
			</div>

			<div
				style={{
					marginTop: '40px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<Stack spacing={2}>
					<Pagination
						count={data.totalPages}
						showFirstButton
						showLastButton
						page={page}
						onChange={handleChange}
						color='primary'
					/>
				</Stack>
			</div>
			<div
				style={{
					position: 'fixed',
					zIndex: 1,
					top: '300px',
					right: '5%',
				}}>
				<WhatsappButton />
			</div>
		</>
	);
}

const initialValues = () => {
	return {
		search: '',
	};
};

const validationSchema = () => {
	return {
		search: Yup.string(),
	};
};

export default Home;
