import React from 'react';
import {
	MDBFooter,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBIcon,
} from 'mdb-react-ui-kit';

const Footer = () => {
	return (
		<MDBFooter
			bgColor='light'
			className='text-center text-lg-start text-muted'>
			<section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
				<div className='me-5 d-none d-lg-block'>
					<span>Mantente conectado con nosotros:</span>
				</div>

				{/* <div>
					<a href='' className='me-4 text-reset'>
						<MDBIcon fab icon='facebook-f' />
					</a>
					<a href='' className='me-4 text-reset'>
						<MDBIcon fab icon='twitter' />
					</a>
					<a href='' className='me-4 text-reset'>
						<MDBIcon fab icon='google' />
					</a>
					<a href='' className='me-4 text-reset'>
						<MDBIcon fab icon='instagram' />
					</a>
					<a href='' className='me-4 text-reset'>
						<MDBIcon fab icon='linkedin' />
					</a>
					<a href='' className='me-4 text-reset'>
						<MDBIcon fab icon='github' />
					</a>
				</div> */}
			</section>

			<section className=''>
				<MDBContainer className='text-center text-md-start mt-5'>
					<MDBRow className='mt-3'>
						<MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
							<h6 className='text-uppercase fw-bold mb-4'>
								<MDBIcon icon='gem' className='me-3' />
								Cysem
							</h6>
							<p>
								CYSEM nace en 2018 como una empresa dedicada a
								la consultoría y servicios especializados con la
								visión de revolucionar la distribución de
								refacciones industriales, además contamos con un
								equipo de ingeniería con mas de 30 años de
								experiencia en la tecnología de rodamientos y en
								la transmisión de potencia en general.
							</p>
						</MDBCol>

						<MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
							<h6 className='text-uppercase fw-bold mb-4'>
								Productos
							</h6>
							<p>
								<a href='#!' className='text-reset'>
									Reductores y motorreductores
								</a>
							</p>
							<p>
								<a href='#!' className='text-reset'>
									Motores
								</a>
							</p>
							<p>
								<a href='#!' className='text-reset'>
									Acoplamientos
								</a>
							</p>
							<p>
								<a href='#!' className='text-reset'>
									Rodamientos
								</a>
							</p>
						</MDBCol>

						{/* <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
							<h6 className='text-uppercase fw-bold mb-4'>
								Useful links
							</h6>
							<p>
								<a href='#!' className='text-reset'>
									Pricing
								</a>
							</p>
							<p>
								<a href='#!' className='text-reset'>
									Settings
								</a>
							</p>
							<p>
								<a href='#!' className='text-reset'>
									Orders
								</a>
							</p>
							<p>
								<a href='#!' className='text-reset'>
									Help
								</a>
							</p>
						</MDBCol> */}

						<MDBCol
							md='4'
							lg='3'
							xl='3'
							className='mx-auto mb-md-0 mb-4'>
							<h6 className='text-uppercase fw-bold mb-4'>
								Contacto
							</h6>
							<p>
								<MDBIcon icon='home' className='me-2' />
								Estado de México, Atizapan de Zaragoza
							</p>
							<p>
								<MDBIcon icon='envelope' className='me-3' />
								ventas01@cysem.org
							</p>
							<p>
								<MDBIcon icon='phone' className='me-3' />
								5593007360 / 5593007359
							</p>
							<p>
								<MDBIcon icon='print' className='me-3' />
								5559393056
							</p>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</section>

			<div
				className='text-center p-4'
				style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
				© 2022 Copyright:
				<a className='text-reset fw-bold' href='/'>
					Cysem.org
				</a>
			</div>
		</MDBFooter>
	);
};

export default Footer;
