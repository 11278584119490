import React from 'react';
import style from './nosotros.module.css';
import logo from '../../assets/images/LogoCysem.png';
import WhatsappButton from '../../components/whatsapp/WhatsappButton';
import motoReductores from '../../assets/images/LOGO_Motoreductores.png';
import motores from '../../assets/images/LOGO_MOTORES.png';
import acoplamientos from '../../assets/images/LOGO_ACOPLAMIENTOS.png';
import rodamientos from '../../assets/images/LOGO_rodamientos.png';
import marcas from '../../assets/images/LOGO_MARCAS.png';

const Nosotros = () => {
	return (
		<>
			<div className={style.principalBox}>
				<div className={style.boxLogo}>
					<img
						src={logo}
						style={{
							marginTop: '20px',
							width: '200px',
						}}
					/>
				</div>
				<div
					style={{
						width: '60%',
						textAlign: 'center',
					}}>
					<h3 className={style.h3}>¿Quienes Somos?</h3>
					<p
						style={{
							fontSize: '20px',
						}}>
						CYSEM nace en 2018 como una empresa dedicada a la
						consultoría y servicios especializados con la visión de
						revolucionar la distribución de refacciones
						industriales, además contamos con un equipo de
						ingeniería con mas de 30 años de experiencia en la
						tecnología de rodamientos y en la transmisión de
						potencia en general.
						<br />
						¡Así que somos especialistas en dar soluciones!
					</p>
				</div>
				<div className={style.boxClientes}>
					<h3 className={style.h3}>Nuestros pruductos y servicios</h3>
					<div>
						<h5>Reductores y motorreductores</h5>
						<ul>
							<li>Coaxiales</li>
							<li>Senfín corona</li>
							<li>Paralelos</li>
							<li>Ortogonales</li>
							<li>Planetarios</li>
							<li>Reenvios</li>
							<li>Pendulares</li>
						</ul>
						<img
							className={style.imgNosotros}
							alt='motoreductores'
							src={motoReductores}
						/>
					</div>
					<div>
						<h5>Motores</h5>
						<ul>
							<li>IEC</li>
							<li>Nema</li>
							<li>Con freni Electromagnético</li>
						</ul>
						<img
							className={style.imgNosotros}
							alt='motoreductores'
							src={motores}
						/>
					</div>
					<div>
						<h5>Acoplamientos</h5>
						<ul>
							<li>Elementos</li>
							<li>Radex</li>
							<li>Rotex</li>
							<li>Bowex</li>
						</ul>
						<img
							className={style.imgNosotros}
							alt='motoreductores'
							src={acoplamientos}
						/>
					</div>
					<div>
						<h5>Rodamientos</h5>
						<ul>
							<li>
								Rodamientos especiales para aplicaciones en la
								industria cementera, Siderúrgica, papelera y
								minera
							</li>
						</ul>
						<img
							className={style.imgNosotros}
							alt='motoreductores'
							src={rodamientos}
						/>
					</div>
				</div>
				<div className={style.boxClientes}>
					<h3 className={style.h3}>Nuestras Marcas</h3>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<img
							className={style.imgMarcas}
							alt='motoreductores'
							src={marcas}
						/>
					</div>
				</div>
			</div>

			<div
				style={{
					position: 'fixed',
					zIndex: 1,
					top: '300px',
					right: '5%',
				}}>
				<WhatsappButton />
			</div>
		</>
	);
};
export default Nosotros;
