import React, { useState, useEffect } from 'react';
import style from './cards.module.css';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';

const Cards = ({ codigo, desc, disponibilidad, marca, precio, user }) => {
	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();
	const [carrito, setCarrito] = useState([]);

	const idUser = user?.id;

	const sendCarrito = async () => {
		if (idUser) {
			setCarrito({ codigo, desc, disponibilidad, marca, precio });
			try {
				const data = {
					user_id: idUser,
					products: [
						{
							codigoProducto: codigo,
							description: desc,
							disponibilidad: disponibilidad,
							marca: marca,
							precio: precio,
						},
					],
				};
				const url = `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/carrito`;
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				});
				const jsonData = await response.json();
				const status = response.status;
				if (status !== 200) {
					MySwal.fire({
						icon: 'error',
						title: 'No se agrego el producto, intentalo nuevamente',
						text: jsonData,
					});
					return;
				}
				if (status === 200) {
					MySwal.fire({
						title: 'Carrito',
						text: 'El producto se agrego correctamente',
						icon: 'success',
						showCancelButton: true,
						cancelButtonText: 'Continuar Comprando',
						confirmButtonText: 'Ir a mi carrito de compras',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: 'green',
					}).then((res) => {
						if (res.isConfirmed) {
							navigate('/carrito');
						}
					});
				}
			} catch (err) {
				console.log(err);
			}
		}
		if (!idUser) {
			MySwal.fire({
				title: 'Carrito',
				text: 'Es necesario Iniciar Sesión para poder comprar en nuestra tienda',
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Iniciar Sesión',
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
			}).then((res) => {
				if (res.isConfirmed) {
					navigate('/login');
				}
			});
		}
	};

	return (
		<div className={style.boxCards}>
			<div className={style.contentCard}>
				<p>
					<b>Codigo de producto:</b> <span>{codigo}</span>
				</p>
				<p>
					<b>Descripcion: </b> <span>{desc}</span>
				</p>
				<p>
					<b>Marca: </b> <span>{marca}</span>
				</p>
				<p>
					<b>Precio: </b> <span>${precio}</span>
				</p>
				<p>
					<b>Disponibilidad: </b> <span>{disponibilidad}</span>
				</p>
			</div>
			<Button
				onClick={sendCarrito}
				type='submit'
				style={{
					backgroundColor: '#0A2B6B',
					color: '#C6F40A',
					fontWeigth: 'bolder',
					width: '200px',
					textTransform: 'none',
					margin: '0',
				}}
				variant='contained'>
				Agregar al carrito
			</Button>
		</div>
	);
};

export default Cards;
