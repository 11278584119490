import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

const SandwichBar = ({ user }) => {
	const [state, setState] = useState({
		Left: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor) => (
		<Box
			role='presentation'
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}>
			<List>
				{['Productos', 'Nosotros'].map((text, index) => (
					<Link
						to={`./${text
							.toLocaleLowerCase()
							.split(' ')
							.join('')}`}>
						<ListItem key={text} disablePadding>
							<ListItemButton>
								<ListItemIcon>
									{index % 2 === 0 ? (
										<InboxIcon />
									) : (
										<MailIcon />
									)}
								</ListItemIcon>
								<ListItemText primary={text} />
							</ListItemButton>
						</ListItem>
					</Link>
				))}
				{!user && (
					<List>
						{['Login', 'Registro'].map((text, index) => (
							<Link
								to={`./${text
									.toLocaleLowerCase()
									.split(' ')
									.join('')}`}>
								<ListItem key={text} disablePadding>
									<ListItemButton>
										<ListItemIcon>
											{index % 2 === 0 ? (
												<InboxIcon />
											) : (
												<MailIcon />
											)}
										</ListItemIcon>
										<ListItemText primary={text} />
									</ListItemButton>
								</ListItem>
							</Link>
						))}
					</List>
				)}
				{user?.role === 'cliente' && (
					<Link to='/carrito'>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<MailIcon />
								</ListItemIcon>
								<ListItemText primary={'Carrito'} />
							</ListItemButton>
						</ListItem>
					</Link>
				)}
			</List>
		</Box>
	);

	return (
		<div>
			{['Menu'].map((anchor) => (
				<div key={anchor}>
					<Button onClick={toggleDrawer(anchor, true)}>
						{anchor}
					</Button>
					<Drawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}>
						{list(anchor)}
					</Drawer>
				</div>
			))}
		</div>
	);
};

export default SandwichBar;
