import React from 'react';
import style from './contacto.module.css';
import logo from '../../assets/images/LogoCysem.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import WhatsappButton from '../../components/whatsapp/WhatsappButton';

const Contacto = () => {
	const navigate = useNavigate();
	const sendMail = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				'service_ede0sqh',
				'template_dbgnb0l',
				e.target,
				'MuWhbwaQj2FFjtayT',
			)
			.then((res) => {
				alert('El mail fue enviado correctamente');
			})
			.catch((err) => {
				console.log(err);
			});
		navigate('/');
	};

	return (
		<>
			<div className={style.boxContacto}>
				<img className={style.logoContacto} alt='logo' src={logo} />
				<h3>
					Dejanos saber tu opinión o consulta por correo electronico,
					será un placer atenderte
				</h3>
				<form onSubmit={sendMail} className={style.formulario}>
					<TextField
						className={style.textField}
						id='outlined-basic'
						variant='outlined'
						size='small'
						name='nombre'
						label='Nombre completo'
						required
						margin='normal'
					/>
					<TextField
						className={style.textField}
						id='outlined-basic'
						variant='outlined'
						size='small'
						name='empresa'
						label='Nombre de la empresa'
						required
						margin='normal'
					/>
					<TextField
						className={style.textField}
						type='email'
						id='outlined-basic'
						variant='outlined'
						size='small'
						name='mail'
						label='Correo de contacto'
						required
						margin='normal'
					/>
					<TextField
						className={style.textField}
						id='outlined-basic'
						variant='outlined'
						size='small'
						name='telefono'
						label='Telefono'
						required
						margin='normal'
					/>
					<TextField
						className={style.textField}
						label='Mensaje'
						multiline
						maxRows={8}
						variant='outlined'
						required
						margin='normal'
						name='mensaje'
					/>
					<Button
						type='submit'
						style={{
							width: '100%',
							marginTop: '10px',
							backgroundColor: '#0A2B6B',
							color: '#C6F40A',
							fontWeigth: 'bolder',
							textTransform: 'none',
						}}
						variant='contained'>
						Enviar
					</Button>
				</form>
			</div>
			<div
				style={{
					position: 'fixed',
					zIndex: 1,
					top: '300px',
					right: '5%',
				}}>
				<WhatsappButton />
			</div>
		</>
	);
};

export default Contacto;
